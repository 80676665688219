import type { FieldMeta } from 'vee-validate';
import { computed, type Ref } from 'vue';

interface Props {
  meta: FieldMeta<unknown>
  errorMessage: Ref<string | undefined>;
}

export default function useFieldError(props: Props) {
  const showError = computed(() => props.errorMessage.value && props.meta.touched);
  const hideError = computed(() => !showError.value);

  return { showError, hideError };
}
