import { api } from './index';

function send(event: string) {
  const path = '/api/internal/events';

  return api({
    method: 'post',
    url: path,
    data: {
      event,
    },
  });
}

export const eventsApi = { send };
