<script lang="ts">
import { PhEye, PhEyeSlash, PhCheckCircle, PhXCircle } from '@phosphor-icons/vue';
import { ref, computed, defineEmits } from 'vue';
import { useI18n } from 'vue-i18n';

import useAttrsWithoutClass from '@/composables/useAttrsWithoutClass';
import { passwordRequirements } from '@/utils/password-utils';

import BaseMokInput from './base-mok-input.vue';
import BaseSvg from './base-svg.vue';

export default {
  inheritAttrs: false,
};
</script>
<script setup lang="ts">

export type PasswordInputProps = {
  modelValue?: string;
  label?: string;
  placeholder?: string;
  name: string;
  needsValidation?: boolean;
}

const { t } = useI18n();

const props = withDefaults(defineProps<PasswordInputProps>(), {
  label: '',
  modelValue: '',
  needsValidation: false,
  placeholder: '',
});

const emit = defineEmits(['update:modelValue']);

const attrsWithoutClass = useAttrsWithoutClass();

const isPasswordVisible = ref(false);
const inputType = computed(() => (isPasswordVisible.value ? 'text' : 'password'));
const isFocused = ref(false);

const requirements = computed(() =>
  passwordRequirements.map((req) => ({
    ...req,
    met: req.regex.test(props.modelValue),
  })),
);

const allRequirementsMet = computed(() => requirements.value.every((req) => req.met));

function togglePasswordVisibility() {
  isPasswordVisible.value = !isPasswordVisible.value;
}

function handleFocus() {
  isFocused.value = true;
}

function handleBlur() {
  isFocused.value = false;
}

function updateModelValue(newValue: string) {
  emit('update:modelValue', newValue);
}

const showTooltip = computed(() => isFocused.value && props.needsValidation && !allRequirementsMet.value);

</script>

<template>
  <div
    class="relative"
    :class="$attrs.class"
  >
    <base-mok-input
      v-bind="attrsWithoutClass"
      :model-value="modelValue"
      :name="name"
      :label="label"
      :type="inputType"
      :placeholder="placeholder"
      :force-hide-error="showTooltip"
      @update:model-value="updateModelValue"
      @focus="handleFocus"
      @blur="handleBlur"
    >
      <template #right>
        <button
          type="button"
          class="absolute right-2 top-1/2 -translate-y-1/2 text-gray-500"
          data-testid="password-eye"
          @click="togglePasswordVisibility"
        >
          <component
            :is="isPasswordVisible ? PhEye : PhEyeSlash"
            class="h-4 w-4 fill-primary"
          />
        </button>
      </template>
    </base-mok-input>
    <div
      v-if="needsValidation && showTooltip"
      class="absolute z-10 mt-2 w-full rounded-2xl bg-gray-50 px-5 py-4 shadow-xl"
    >
      <base-svg
        name="tooltip-arrow"
        class="absolute left-4 top-[-7px] z-10 rotate-180 fill-gray-50"
      />
      <ul class="flex flex-col gap-1">
        <li
          v-for="requirement in requirements"
          :key="requirement.key"
          :class="[
            requirement.met ? 'text-green-600' : 'text-red-600',
            'flex items-center justify-start gap-2 text-xs',
          ]"
        >
          <PhCheckCircle
            v-if="requirement.met"
            class="h-4 w-4 shrink-0 fill-green-600"
          />
          <PhXCircle
            v-else
            class="h-4 w-4 shrink-0 fill-red-600"
          />
          <span>{{ t(`validation.password.${requirement.key}`) }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>
