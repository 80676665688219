<script setup lang="ts">
import { useField } from 'vee-validate';
import { computed, ref, toRef, useAttrs, useSlots } from 'vue';

interface Props {
  modelValue?: string | number | unknown[];
  label?: string;
  name: string;
}

const props = withDefaults(defineProps<Props>(), {
  label: '',
  modelValue: undefined,
});

const name = toRef(props, 'name');

const {
  value: inputValue,
  errorMessage,
  meta,
  handleChange,
} = useField(name, undefined, {
  initialValue: props.modelValue,
});

function handleBlur() {
  isFocused.value = false;
}

const { left: leftSlot } = useSlots();
const isFocused = ref(false);
const showFloatingLabel = computed(() => props.label && (isFocused.value || inputValue.value || leftSlot));

const attrs = useAttrs();
const attrsWithoutClass = Object.fromEntries(Object.entries(attrs).filter(([key, _]) => key !== 'class'));
</script>

<script lang="ts">
export default {
  inheritAttrs: false,
};
</script>

<template>
  <div :class="$attrs.class">
    <div
      data-testid="input-div"
      class="relative flex overflow-auto rounded border text-gray-500"
      :class="[
        isFocused ? 'border-gray-400' : 'border-gray-200',
        {
          'border-error-600': !!errorMessage && !!meta.touched ,
        }
      ]"
    >
      <div
        v-if="leftSlot"
        class="flex gap-x-2 px-2 pb-1 pt-5"
      >
        <slot name="left" />
      </div>
      <label
        v-if="showFloatingLabel"
        :for="name"
        class="absolute left-2 top-1 text-xs text-gray-500"
      >
        {{ label }}
      </label>
      <input
        v-bind="attrsWithoutClass"
        :id="name"
        :value="inputValue"
        :name="name"
        :placeholder="showFloatingLabel ? '' : label"
        class="w-full px-2 placeholder:text-sm placeholder:text-gray-500 focus:outline-none"
        :class="showFloatingLabel ? 'pb-1 pt-5' : 'py-3'"
        @input="handleChange"
        @blur="handleBlur"
        @focus="isFocused = true"
      >
    </div>
    <p
      v-if="errorMessage && !!meta.touched"
      data-testid="error"
      class="text-right text-xs text-error-600"
    >
      {{ errorMessage }}
    </p>
  </div>
</template>
