import { load, ReCaptchaInstance } from 'recaptcha-v3';
import { ref, computed, type Ref } from 'vue';

import { useSiteVariables } from './useSiteVariables';

const recaptcha: Ref<ReCaptchaInstance | null> = ref(null);
const recaptchaLoaded: Ref<boolean> = computed(() => recaptcha.value !== null);

export function useRecaptcha() {
  async function loadRecaptcha() {
    const { recaptchaSiteKey } = useSiteVariables();

    if (!!recaptchaSiteKey) {
      load(recaptchaSiteKey).then((recaptchaInstance) => {
        recaptcha.value = recaptchaInstance;
      });
    }
  }

  async function executeRecaptcha(action: string): Promise<string | undefined> {
    if (recaptcha.value instanceof ReCaptchaInstance) {
      return await recaptcha.value.execute(action);
    }

    return '';
  }

  return {
    executeRecaptcha,
    loadRecaptcha,
    recaptchaLoaded,
  };
}
