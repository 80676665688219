type SiteVariables = {
  locale: string;
  logoUrl: string;
  recaptchaSiteKey?: string;
}

export interface SponsorAppWindow extends Window {
  siteVariables: SiteVariables;
}

export function useSiteVariables() {
  const siteVariables = (window as SponsorAppWindow & typeof globalThis).siteVariables;
  const localeWithoutDash = siteVariables.locale.replace('-', '');

  return {
    ...siteVariables,
    locale: localeWithoutDash,
  };
}
