const BRASIL_DNI_LENGTH = 11;
const CHECK_DIGIT_INITIAL_MULTIPLIER = 2;
const CHECK_DIGIT_SUM_DIVISOR = 11;
const CHECK_DIGIT_REMAINDER_LIMIT = 2;

function isCheckDigitValid(dniPart:string) {
  const checkDigit = parseInt(dniPart.slice(-1), 10);
  const dniPartWithoutCheckDigit = dniPart.slice(0, -1);
  const sum = dniPartWithoutCheckDigit.split('').reverse().reduce(
    (acc, digit, index) => acc + (parseInt(digit, 10) * (index + CHECK_DIGIT_INITIAL_MULTIPLIER)),
    0,
  );

  const remainder = sum % CHECK_DIGIT_SUM_DIVISOR;
  const expectedCheckDigit = remainder < CHECK_DIGIT_REMAINDER_LIMIT ? 0 : CHECK_DIGIT_SUM_DIVISOR - remainder;

  return checkDigit === expectedCheckDigit;
}

function clean(dni: string) {
  return dni.replaceAll(/[^0-9]/g, '');
}

function format(dni: string) {
  return clean(dni)
    .replace(/(\d+)(\d{2})/, '$1-$2')
    .replaceAll(/\B(?=(\d{3})+(?!\d))/g, '.');
}

function validate(dni: string) {
  const cleanDni = clean(dni);
  const hasCorrectLength = cleanDni.length === BRASIL_DNI_LENGTH;
  const isFirstCheckDigitValid = isCheckDigitValid(cleanDni.slice(0, -1));
  const isSecondCheckDigitValid = isCheckDigitValid(cleanDni);

  return hasCorrectLength && isFirstCheckDigitValid && isSecondCheckDigitValid;
}

export const brDniUtils = { format, validate };
