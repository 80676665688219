<script setup lang="ts">
import { PhCheck, PhMinus } from '@phosphor-icons/vue';
import { useField } from 'vee-validate';
import { computed, toRef } from 'vue';

import useAttrsWithoutClass from '@/composables/useAttrsWithoutClass';

type Size = 'medium' | 'small';
type Color = 'primary' | 'purple';

type ColorClasses = {
  [key in Color]: string;
};

export type Props = {
  modelValue?: boolean | unknown[];
  name: string;
  disabled?: boolean;
  size?: Size;
  indeterminated?: boolean;
  color?: Color;
  value?: unknown;
};

const props = withDefaults(defineProps<Props>(), {
  modelValue: undefined,
  disabled: false,
  size: 'medium',
  indeterminated: false,
  color: 'primary',
});
const attrsWithoutClass = useAttrsWithoutClass();

const name = toRef(props, 'name');

const inputSizeClass = {
  medium: 'h-6 w-6',
  small: 'h-4 w-4',
};

const iconSize = {
  medium: 12,
  small: 8,
};

const iconLeftSpace = {
  medium: 'left-3',
  small: 'left-2',
};

const getIconSize = computed(() => iconSize[props.size]);

const value = toRef(props, 'value');

const { handleChange, checked, checkedValue } = useField(name, undefined, {
  type: 'checkbox',
  checkedValue: value.value || true,
  uncheckedValue: false,
  initialValue: props.modelValue,
  syncVModel: true,
});

/* Force tailwind to include the possible classes in bundle */
const colorClasses: ColorClasses = {
  primary: 'border-primary-700 bg-primary-700 hover:border-primary-600 hover:bg-primary-600',
  purple: 'border-purple-700 bg-purple-700 hover:border-purple-600 hover:bg-purple-600',
};

const baseStyle = computed(() => {
  const isDisabled = props.disabled;

  return [
    'cursor-pointer appearance-none rounded border transition-colors duration-150',
    inputSizeClass[props.size],
    {
      [`border-${props.color}-700`]: true,
      [`bg-${props.color}-700`]: checked?.value,
      'disabled:bg-gray-200 disabled:border-gray-200': isDisabled,
      [`hover:border-${props.color}-600`]: !isDisabled && checked?.value,
      [`hover:bg-${props.color}-600`]: !isDisabled && checked?.value,
    },
  ];
});
</script>

<template>
  <div class="flex items-center">
    <label class="relative flex cursor-pointer items-center">
      <input
        type="checkbox"
        :name="name"
        :disabled="props.disabled"
        :class="baseStyle"
        :checked="checked"
        v-bind="attrsWithoutClass"
        @change="handleChange(checkedValue)"
      >
      <span
        class="pointer-events-none absolute top-1/2 -translate-x-1/2 -translate-y-1/2"
        :class="{
          'opacity-100': checked,
          'opacity-0': !checked,
          'text-gray-400': props.disabled,
          'text-gray-200': !props.disabled,
          [iconLeftSpace[props.size]]: true,
        }"
      >
        <PhCheck
          v-if="!props.indeterminated"
          :size="getIconSize"
          weight="bold"
        />
        <PhMinus
          v-else
          :size="getIconSize"
          weight="bold"
        />
      </span>
      <div class="ml-2 text-sm">
        <slot />
      </div>
    </label>
  </div>
</template>
