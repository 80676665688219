import enUSLocale from './enUS.json';
import esCLLocale from './esCL.json';
import esEsLocale from './esES.json';
import frFRLocale from './frFR.json';
import ptBRLocale from './ptBR.json';
import ptPTLocale from './ptPT.json';

const messages = {
  enUS: enUSLocale,
  esCL: esCLLocale,
  esES: esEsLocale,
  frFR: frFRLocale,
  ptBR: ptBRLocale,
  ptPT: ptPTLocale,
};

export const locales = { messages };
