<script setup lang="ts">
import { PhInfo } from '@phosphor-icons/vue';
import { computed, inject, onBeforeUnmount, onMounted, ref } from 'vue';

import type { RegistrationGuide } from '@/types/registration-guide';

import BaseMokInput from './base-mok-input.vue';
import BaseMokTooltipSimpleContent from './base-mok-tooltip-simple-content.vue';
import BaseMokTooltip, { type TooltipPlacement } from './base-mok-tooltip.vue';

type Props = {
  label: string;
  placeholder: string;
  name: string;
}

const props = defineProps<Props>();
const registrationGuide = inject('registrationGuide') as RegistrationGuide | undefined;
const windowWidth = ref(window.innerWidth);
const TAILWIND_XL_BREAKPOINT = 1280;

const tooltipPlacement = computed<TooltipPlacement>(() =>
  (windowWidth.value < TAILWIND_XL_BREAKPOINT ? 'top' : 'right'));

function handleResize() {
  windowWidth.value = window.innerWidth;
}

onMounted(() => {
  handleResize();
  window.addEventListener('resize', handleResize);
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', handleResize);
});
</script>

<template>
  <base-mok-input
    :label="props.label"
    :placeholder="props.placeholder"
    :name="props.name"
  >
    <template #right>
      <base-mok-tooltip
        v-if="registrationGuide"
        arrow-position="right"
        :tooltip-placement="tooltipPlacement"
        :offset-in-px="24"
      >
        <template #reference>
          <PhInfo
            class="size-4 shrink-0 fill-primary"
          />
        </template>
        <template #content>
          <base-mok-tooltip-simple-content
            :description="registrationGuide.description"
            :title="registrationGuide.title"
            :image="registrationGuide.imageUrl"
            :has-unlimited-description="true"
          />
        </template>
      </base-mok-tooltip>
    </template>
  </base-mok-input>
</template>
