import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { object, string, ref as yupRef } from "yup";

export const passwordRequirements = [
  {
    key: 'minLength',
    regex: /.{8,}/,
  },
  {
    key: 'uppercase',
    regex: /\p{Lu}/u,
  },
  {
    key: 'lowercase',
    regex: /\p{Ll}/u,
  },
  {
    key: 'specialChar',
    regex: /[^\p{L}\p{N}]/u,
  },
  {
    key: 'number',
    regex: /\p{Nd}/u,
  },
];

export function getPasswordValidationSchema() {
  const { t } = useI18n();

  return computed(() =>
    object().shape({
      password: string()
        .required()
        .test(
          'password-strength',
          t('validation.password.requirements'),
          (value) => {
            if (!value) return false;

            return passwordRequirements.every((req) => req.regex.test(value));
          },
        ),
      passwordConfirmation: string()
        .required()
        .oneOf([yupRef('password')], t('validation.password.passwordConfirmation')),
    }),
  );
}
