<script setup lang="ts">
import { computed } from 'vue';

export type Props = {
  title?: string,
  description: string,
  image?: string,
  hasUnlimitedDescription?: boolean;
}

const props = withDefaults(
  defineProps<Props>(),
  {
    title: undefined,
    image: undefined,
    hasUnlimitedDescription: false,
  },
);

const limitDescriptionLength = !props.hasUnlimitedDescription;

const baseDescriptionStyles = {
  'simple': 'text-gray-800 max-w-[33.3333vw] sm:max-w-[312px]',
  'simple-text': 'text-gray-700 min-w-[208px]',
  'simple-text-image': 'text-gray-700',
};

const lineClampClasses = {
  'simple': 'line-clamp-1',
  'simple-text': 'line-clamp-4',
  'simple-text-image': 'line-clamp-3',
};

const variant = computed(() => {
  if (props.title && props.image) return 'simple-text-image';
  else if (props.title) return 'simple-text';

  return 'simple';
});

</script>

<template>
  <div
    class="max-w-[296px]"
  >
    <img
      v-if="props.image"
      :src="props.image"
      class="mb-4 max-h-[296px] min-w-[296px] overflow-hidden rounded-lg object-contain"
    >
    <h6
      v-if="props.title"
      class="mb-2 text-left text-sm font-medium text-gray-800"
    >
      {{ props.title }}
    </h6>
    <p
      v-if="props.description"
      class="text-xs"
      :class="[
        baseDescriptionStyles[variant],
        limitDescriptionLength ? lineClampClasses[variant] : ''
      ]"
    >
      {{ props.description }}
    </p>
  </div>
</template>
