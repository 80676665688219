<script setup lang="ts">
import { useForm } from 'vee-validate';
import { useI18n } from 'vue-i18n';

import type { UserForm } from '@/api/users';
import type { UserSessionMode } from '@/types/user-session-mode';
import { getPasswordValidationSchema } from '@/utils/password-utils';

import BaseMokButton from './base-mok-button.vue';
import UserSessionHeader from './user-session-header.vue';

const { t } = useI18n();

const emit = defineEmits<{(event: 'toggle-mode', value?: UserSessionMode): void;
  (event: 'submit', values: UserForm): void;
}>();

const validationSchema = getPasswordValidationSchema();

const { values, handleSubmit, meta, setFieldValue } = useForm<UserForm>(
  {
    validationSchema,
    validateOnMount: true,
  },
);

function getResetPasswordToken(): string {
  const urlParams = new URLSearchParams(window.location.search);

  return urlParams.get('reset_password_token') || '';
}

const submitForm = handleSubmit(() => {
  setFieldValue('resetPasswordToken', getResetPasswordToken());
  emit('submit', values);
});

</script>

<template>
  <user-session-header
    :title="t('userSession.updatePasswordPage.title')"
  />
  <form class="flex flex-col gap-y-4">
    <base-password-input
      v-model="values.password"
      :label="t('userSession.password')"
      :placeholder="t('userSession.password')"
      type="password"
      autocomplete="new-password"
      name="password"
      needs-validation
    />
    <base-password-input
      v-model="values.passwordConfirmation"
      class="pb-4"
      :label="t('userSession.passwordConfirmation')"
      :placeholder="t('userSession.passwordConfirmation')"
      type="password"
      autocomplete="new-password"
      name="passwordConfirmation"
    />
    <base-mok-button
      variant="primary"
      :label="t('userSession.updatePassword')"
      :disabled="!meta.valid"
      @click="submitForm"
    />
  </form>
</template>
