<!-- eslint-disable no-magic-numbers -->
<script setup lang="ts">
import {
  useFloating,
  offset,
  flip,
} from '@floating-ui/vue';
import { ref, computed, watch } from 'vue';

import BaseSvg from './base-svg.vue';

type ArrowPosition = 'center' | 'left' | 'right' | 'none';
export type TooltipPlacement = 'left' | 'right' | 'top' | 'bottom';
type TooltipOffset = 4 | 8 | 16 | 24;

export type Props = {
  arrowPosition?: ArrowPosition;
  tooltipPlacement?: TooltipPlacement;
  show?: boolean;
  offsetInPx?: TooltipOffset;
  useFlip?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  arrowPosition: 'center',
  tooltipPlacement: 'top',
  show: false,
  offsetInPx: 8,
  useFlip: false,
});

const placement = ref(props.tooltipPlacement);
const reference = ref<HTMLElement | null>(null);
const floatingTooltip = ref<HTMLElement | null>(null);
const floatingArrow = ref<HTMLElement | null>(null);
const ARROW_HEIGHT = 8;
const ARROW_WIDTH = 17;

const isVisible = ref(props.show);
const offsetInPx = ref(props.offsetInPx);

watch(() => props.show, (newVal) => {
  isVisible.value = newVal;
});

watch(() => props.tooltipPlacement, (newVal) => {
  placement.value = newVal;
});

function showTooltip() {
  isVisible.value = true;
}

function hideTooltip() {
  isVisible.value = false;
}

const isVertical = computed(() => placement.value.startsWith('top') || placement.value.startsWith('bottom'));
const isStart = computed(() => placement.value.startsWith('top') || placement.value.startsWith('right'));
const isEnd = computed(() => placement.value.startsWith('bottom') || placement.value.startsWith('left'));
const isArrowLeft = computed(() => props.arrowPosition === 'left');

function calculateShiftAmount(arrowShift: number, arrowSize: number, floatingSize: number) {
  return (floatingSize / 2) - (arrowShift + arrowSize / 2);
}

function getArrowShift() {
  const arrowShift = isStart.value ? ARROW_WIDTH : -ARROW_WIDTH;

  return isEnd.value ? -arrowShift : arrowShift;
}
const middleware = computed(() => [
  offset(({ rects }) => {
    const floatingSize = isVertical.value ? rects.floating.width : rects.floating.height;
    let crossAxisOffset = 0;

    if (!['center', 'none'].includes(props.arrowPosition)) {
      const arrowShift = getArrowShift();
      const adjustedArrowShift = isEnd.value ? -arrowShift : arrowShift;
      const arrowSize = isVertical.value ? ARROW_WIDTH : ARROW_HEIGHT;
      const shiftAmount = calculateShiftAmount(adjustedArrowShift, arrowSize, floatingSize);

      crossAxisOffset = isArrowLeft.value ? shiftAmount : -shiftAmount;
    }

    const mainAxisOffset = offsetInPx.value + ARROW_HEIGHT;

    return {
      mainAxis: mainAxisOffset,
      crossAxis: crossAxisOffset,
    };
  }),
]);

if (props.useFlip) {
  middleware.value.push(flip());
}

const { x, y, placement: computedPlacement, strategy } = useFloating(
  reference,
  floatingTooltip,
  {
    placement,
    middleware,
  },
);

const floatingTooltipStyles = computed(() => ({
  position: strategy.value,
  top: `${y.value}px`,
  left: `${x.value}px`,
}));

function getOppositeSide(side: string): string {
  const oppositeSides: Record<string, string> = {
    top: 'bottom',
    bottom: 'top',
    left: 'right',
    right: 'left',
  };

  return oppositeSides[side];
}

function getArrowRotation() {
  const rotationMap: { [key: string]: string } = {
    top: 'rotate(0deg)',
    bottom: 'rotate(180deg)',
    left: 'rotate(-90deg)',
    right: 'rotate(90deg)',
  };

  const direction = computedPlacement.value.split('-')[0];

  return rotationMap[direction] || '';
}

const arrowStyles = computed(() => {
  const placementSide = computedPlacement.value.split('-')[0];
  const oppositeSide = getOppositeSide(placementSide);
  const arrowOffset = '16px';

  const baseStyles: Record<string, string> = {
    transform: getArrowRotation(),
    [oppositeSide]: isVertical.value ? `-${ARROW_HEIGHT}px` : `-${ARROW_HEIGHT * 1.5}px`,
  };

  const horizontalStyles: Record<string, string> = {
    left: arrowOffset,
    center: `calc(50% - ${ARROW_WIDTH / 2}px)`,
    right: `calc(100% - ${arrowOffset} - ${ARROW_WIDTH}px)`,
  };

  const verticalStyles: Record<string, string> = {
    left: arrowOffset,
    center: `calc(50% - ${ARROW_HEIGHT / 2}px)`,
    right: `calc(100% - ${arrowOffset} - ${ARROW_HEIGHT}px)`,
  };

  if (isVertical.value) {
    baseStyles.left = horizontalStyles[props.arrowPosition] || horizontalStyles.center;
  } else {
    baseStyles.top = verticalStyles[props.arrowPosition] || verticalStyles.center;
  }

  return baseStyles;
});
</script>

<template>
  <div
    class="w-min"
    type="button"
    @mouseover="showTooltip"
    @mouseleave="hideTooltip"
    @focus="showTooltip"
    @blur="hideTooltip"
  >
    <div ref="reference">
      <slot name="reference" />
    </div>
    <div
      v-if="isVisible"
      ref="floatingTooltip"
      class="rounded-xl bg-gray-50 p-4 shadow-lg"
      :style="floatingTooltipStyles"
    >
      <slot name="content" />
      <base-svg
        v-if="props.arrowPosition !== 'none'"
        ref="floatingArrow"
        class="absolute fill-gray-50 drop-shadow-lg"
        name="tooltip-arrow"
        :style="arrowStyles"
      />
    </div>
  </div>
</template>
