<script setup lang="ts">
import { PhCircleNotch } from '@phosphor-icons/vue';
import { computed, useAttrs, type ComputedRef, type Component } from 'vue';

import type { Color } from '@/types/color';

type Variant = 'primary' | 'secondary' | 'link';
type Tone = 'dark' | 'light';

type VariantClasses = {
  [key in Variant]: string;
};

type ColorClasses = {
  [key in Color]: string;
};

type ToneIntensities = {
  [key in Tone]: number;
};

export type Props = {
  variant?: Variant;
  size?: 'small' | 'medium' | 'large';
  label?: string;
  icon?: Component;
  iconPosition?: 'left' | 'right';
  loading?: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
  roundedFull?: boolean;
  color?: Color;
  tone?: Tone;
};

const props = withDefaults(
  defineProps<Props>(),
  {
    variant: 'primary',
    tone: 'dark',
    color: 'primary',
    size: 'medium',
    label: undefined,
    icon: undefined,
    iconPosition: 'left',
    loading: false,
    disabled: false,
    fullWidth: false,
    roundedFull: false,
  },
);

const attrs = useAttrs();
const currentTag = computed(() => {
  const hasHrefAndIsNotDisabled = attrs.href && !props.disabled;

  return hasHrefAndIsNotDisabled ? 'a' : 'button';
});

/* Force tailwind to include the possible classes in bundle */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const colorClasses : ColorClasses = {
  primary: `bg-primary-700 bg-primary-400 border-primary-700 border-primary-400
    hover:bg-primary-600 hover:border-primary-600 hover:border-primary-300
    text-primary-700 text-primary-600 text-primary-500
    text-primary-400 text-primary-300 text-primary-200`,
  secondary: `bg-secondary-700 bg-secondary-400 border-secondary-700 border-secondary-400
    hover:bg-secondary-600 hover:border-secondary-600 hover:border-secondary-300
    text-secondary-700 text-secondary-600 text-secondary-500
    text-secondary-400 text-secondary-300 text-secondary-200`,
  success: `bg-success-700 bg-success-400 border-success-700 border-success-400
    hover:bg-success-600 hover:border-success-600 hover:border-success-300
    text-success-700 text-success-600 text-success-500
    text-success-400 text-success-300 text-success-200`,
  error: `bg-error-700 bg-error-400 border-error-700 border-error-400
    hover:bg-error-600 hover:border-error-600 hover:border-error-300
    text-error-700 text-error-600 text-error-500
    text-error-400 text-error-300 text-error-200`,
  warning: `bg-warning-700 bg-warning-400 border-warning-700 border-warning-400
    hover:bg-warning-600 hover:border-warning-600 hover:border-warning-300
    text-warning-700 text-warning-600 text-warning-500
    text-warning-400 text-warning-300 text-warning-200`,
  gray: `bg-gray-700 bg-gray-400 border-gray-700 border-gray-400
    hover:bg-gray-600 hover:border-gray-600 hover:border-gray-300
    text-gray-700 text-gray-600 text-gray-500
    text-gray-400 text-gray-300 text-gray-200`,
  'blue-gray': `bg-blue-gray-700 bg-blue-gray-400 border-blue-gray-700 border-blue-gray-400
    hover:bg-blue-gray-600 hover:border-blue-gray-600 hover:border-blue-gray-300
    text-blue-gray-700 text-blue-gray-600 text-blue-gray-500
    text-blue-gray-400 text-blue-gray-300 text-blue-gray-200`,
  'blue-light': `bg-blue-light-700 bg-blue-light-400 border-blue-light-700 border-blue-light-400
    hover:bg-blue-light-600 hover:border-blue-light-600 hover:border-blue-light-300
    text-blue-light-700 text-blue-light-600 text-blue-light-500
    text-blue-light-400 text-blue-light-300 text-blue-light-200`,
  blue: `bg-blue-700 bg-blue-400 border-blue-700 border-blue-400
    hover:bg-blue-600 hover:border-blue-600 hover:border-blue-300
    text-blue-700 text-blue-600 text-blue-500
    text-blue-400 text-blue-300 text-blue-200`,
  indigo: `bg-indigo-700 bg-indigo-400 border-indigo-700 border-indigo-400
    hover:bg-indigo-600 hover:border-indigo-600 hover:border-indigo-300
    text-indigo-700 text-indigo-600 text-indigo-500
    text-indigo-400 text-indigo-300 text-indigo-200`,
  purple: `bg-purple-700 bg-purple-400 border-purple-700 border-purple-400
    hover:bg-purple-600 hover:border-purple-600 hover:border-purple-300
    text-purple-700 text-purple-600 text-purple-500
    text-purple-400 text-purple-300 text-purple-200`,
  pink: `bg-pink-700 bg-pink-400 border-pink-700 border-pink-400
    hover:bg-pink-600 hover:border-pink-600 hover:border-pink-300
    text-pink-700 text-pink-600 text-pink-500
    text-pink-400 text-pink-300 text-pink-200`,
  rose: `bg-rose-700 bg-rose-400 border-rose-700 border-rose-400
    hover:bg-rose-600 hover:border-rose-600 hover:border-rose-300
    text-rose-700 text-rose-600 text-rose-500
    text-rose-400 text-rose-300 text-rose-200`,
  orange: `bg-orange-700 bg-orange-400 border-orange-700 border-orange-400
    hover:bg-orange-600 hover:border-orange-600 hover:border-orange-300
    text-orange-700 text-orange-600 text-orange-500
    text-orange-400 text-orange-300 text-orange-200`,
};

const toneIntensities : ToneIntensities = {
  dark: 700,
  light: 400,
};
const toneIntensity = computed(() => toneIntensities[props.tone]);
// eslint-disable-next-line no-magic-numbers
const lowerToneIntensity = computed(() => toneIntensity.value - 100);
// eslint-disable-next-line no-magic-numbers
const lowestToneIntensity = computed(() => toneIntensity.value - 200);

const buttonVariantClasses : ComputedRef<VariantClasses> = computed(() => ({
  primary: `border text-white bg-${props.color}-${toneIntensity.value} border-${props.color}-${toneIntensity.value}
    hover:bg-${props.color}-${lowerToneIntensity.value} hover:border-${props.color}-${lowerToneIntensity.value}
    disabled:bg-gray-200 disabled:text-gray-400 disabled:border-gray-200 `,
  secondary: `bg-white border border-${props.color}-${toneIntensity.value} text-${props.color}-${toneIntensity.value}
    hover:border-${props.color}-${lowerToneIntensity.value} hover:text-${props.color}-${toneIntensity.value}
    disabled:border-gray-400 disabled:text-gray-400`,
  link: `text-${props.color}-${toneIntensity.value} hover:text-${props.color}-${lowestToneIntensity.value}`,
}));

const buttonSizeStyles = computed(() => ({
  small: props.variant === 'link' ? 'gap-x-1' : 'gap-x-1 py-2',
  medium: props.variant === 'link' ? 'gap-x-2' : 'gap-x-2 py-3',
  large: props.variant === 'link' ? 'gap-x-2' : 'gap-x-3 py-4',
}));

const buttonPaddingsForLabel = {
  small: 'px-3',
  medium: 'px-6',
  large: 'px-10',
};

const buttonPaddingsForIcon = {
  small: 'px-2',
  medium: 'px-3',
  large: 'px-4',
};

const iconSizeStyles = {
  small: 'h-3 w-3',
  medium: 'h-4 w-4',
  large: 'h-6 w-6',
};

const labelSizeStyles = {
  small: 'text-2xs leading-3',
  medium: 'text-sm leading-4',
  large: 'text-base leading-6',
};

const buttonPaddingsForLabelAndIcon = {
  left: {
    small: 'pl-2 pr-3',
    medium: 'pl-4 pr-6',
    large: 'pl-4 pr-6',
  },
  right: {
    small: 'pr-2 pl-3',
    medium: 'pr-4 pl-6',
    large: 'pr-4 pl-6',
  },
};

const buttonVariantStyle = computed(() => buttonVariantClasses.value[props.variant]);
const buttonSizeStyle = computed(() => buttonSizeStyles.value[props.size]);
const buttonPaddingStyle = computed(() => {
  if (props.variant === 'link') {
    return '';
  }

  if (props.icon && props.label) {
    return buttonPaddingsForLabelAndIcon[props.iconPosition][props.size];
  } else if (props.label) {
    return buttonPaddingsForLabel[props.size];
  }

  return buttonPaddingsForIcon[props.size];
});

const buttonBorderRadiusStyle = computed(() => {
  if (props.variant === 'link') return '';
  if (props.roundedFull) return 'rounded-full';

  return {
    small: 'rounded-md',
    medium: 'rounded-lg',
    large: 'rounded-xl',
  }[props.size];
});

const iconSizeStyle = computed(() => iconSizeStyles[props.size]);
const labelSizeStyle = computed(() => labelSizeStyles[props.size]);
const iconAtRight = computed(() => props.iconPosition === 'right');

</script>
<template>
  <component
    :is="currentTag"
    :disabled="disabled"
    v-bind="attrs"
    class="relative inline-flex h-min items-center justify-center transition-colors duration-150 ease-linear"
    :class="[
      buttonVariantStyle,
      buttonSizeStyle,
      buttonBorderRadiusStyle,
      buttonPaddingStyle,
      { 'w-full grow': fullWidth,
        'flex-row-reverse': iconAtRight }
    ]"
  >
    <component
      :is="icon"
      v-if="icon"
      class="text-current"
      :class="[iconSizeStyle, { 'invisible': loading }]"
    />
    <span
      v-if="label"
      data-testid="label"
      class="font-medium"
      :class="[labelSizeStyle, { 'invisible': loading }]"
    >
      {{ label }}
    </span>
    <ph-circle-notch
      v-if="loading"
      class="absolute h-6 animate-spin stroke-current"
      :class="iconSizeStyle"
    />
  </component>
</template>
