const ENDING_NIF_OR_NIE_LETTERS = 'TRWAGMYFPDXBNJZSQVHLCKE';
const NIF_OR_NIE_DIVIDER = 23;
const NIE_LETTER_MAP = {
  'X': 0,
  'Y': 10000000,
  'Z': 20000000,
} as const;

function format(nif: string) {
  return nif.replace(/\s/g, '').toUpperCase();
}

function validateControlLetter(number: number, controlLetter: string) {
  const remainder = number % NIF_OR_NIE_DIVIDER;
  const expectedLetter = ENDING_NIF_OR_NIE_LETTERS[remainder];

  return controlLetter === expectedLetter;
}

function validateNif(nif: string) {
  const formattedNif = format(nif);
  const nifRegex = /^[0-9]{8}[A-Z]$/;
  if (nifRegex.test(formattedNif)) {
    const nifNumber = parseInt(formattedNif.slice(0, -1), 10);
    const controlLetter = formattedNif.slice(-1);

    return validateControlLetter(nifNumber, controlLetter);
  }

  return false;
}

function getNieNumber(formattedNie: string) {
  const nieStartLetter = formattedNie[0] as 'X' | 'Y' | 'Z';

  return parseInt(formattedNie.slice(1, -1), 10) + NIE_LETTER_MAP[nieStartLetter];
}

function validateNie(nie: string) {
  const formattedNie = format(nie);
  const nieRegex = /^[XYZ][0-9]{7}[A-Z]$/;
  if (nieRegex.test(formattedNie)) {
    const controlLetter = formattedNie.slice(-1);
    const nieNumber = getNieNumber(formattedNie);

    return validateControlLetter(nieNumber, controlLetter);
  }

  return false;
}

function validate(nif: string) {
  return validateNif(nif) || validateNie(nif);
}

export const esDniUtils = { format, validate };
