<script setup lang="ts">
import {
  Dialog,
  DialogOverlay,
  DialogPanel,
} from '@headlessui/vue';
import { PhX, PhArrowLeft } from '@phosphor-icons/vue';
import DOMPurify from 'dompurify';
import { computed, ref, type Component } from 'vue';
import { useI18n } from 'vue-i18n';

import BaseMokIcon from '@/components/base-mok-icon.vue';

import BaseMokButton from './base-mok-button.vue';

export type Props = {
  isOpen: boolean;
  title: string;
  content: string;
  icon?: Component;
  arrow?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  icon: undefined,
  arrow: true,
});

const emits = defineEmits<{(e: 'close'): void, (e: 'confirm'): void, (e: 'cancel'): void }>();
const { t } = useI18n();
const isBottom = ref(false);
const sanitizedContent = computed(() => DOMPurify.sanitize(props.content));
const initialFocusRef = ref<HTMLElement>();

function accept() {
  emits('confirm');
}

function cancel() {
  emits('cancel');
}

function closeModal() {
  emits('close');
}

function isScrollableElement(element: EventTarget | null): element is HTMLElement {
  return element instanceof HTMLElement;
}

function onScroll(event: Event) {
  const target = event.target;
  if (!isScrollableElement(target)) return;

  const scrollMargin = 112;
  if (target.scrollTop + target.clientHeight + scrollMargin >= target.scrollHeight) {
    isBottom.value = true;

    return;
  }
  isBottom.value = false;
}

</script>

<template>
  <Dialog
    as="div"
    class="relative z-10"
    data-testid="base-mok-modal-document-dialog"
    :open="isOpen"
    :initial-focus="initialFocusRef"
    @close="closeModal"
  >
    <DialogOverlay class="fixed inset-0 bg-white/60 backdrop-blur md:bg-gray-700/70" />
    <div class="fixed bottom-0 flex h-5/6 w-screen  justify-center text-center md:h-full md:items-center">
      <DialogPanel
        class="flex size-full flex-col overflow-y-auto rounded-t-2xl border-2 border-gray-200 bg-white text-left align-middle shadow-xl transition-all md:h-4/6 md:w-7/12	md:rounded-2xl"
        v-bind="$attrs"
      >
        <header class="relative flex flex-row items-center gap-4 px-6 pb-4 pt-6">
          <PhArrowLeft
            v-if="arrow"
            :size="24"
          />
          <base-mok-icon
            v-if="icon"
            color="primary"
            :icon="icon"
            size="lg"
          />
          <h3 class="text-xl font-semibold">
            {{ title }}
          </h3>
          <button
            class="absolute right-6"
            @click="closeModal"
          >
            <PhX
              :size="24"
              class="fill-black"
            />
          </button>
        </header>
        <div
          class="relative h-5/6 overflow-y-scroll border-y-2 border-gray-200 px-6 pt-6"
          @scroll="onScroll"
        >
          <!-- eslint-disable vue/no-v-html -->
          <div
            data-testid="sanitized-content"
            v-html="sanitizedContent"
          />
          <div
            v-if="!isBottom"
            data-testid="fade"
            class="sticky bottom-[-2px] h-28 w-full bg-gradient-to-b from-white/20 from-[64%] to-white to-100%"
          />
        </div>
        <div class="flex flex-row justify-around gap-4 p-3 md:items-center md:justify-end md:p-6">
          <base-mok-button
            :label="t('common.cancel')"
            class="w-full md:w-auto"
            variant="secondary"
            @click="cancel"
          />
          <base-mok-button
            :label="t('common.accept')"
            class="w-full md:w-auto"
            @click="accept"
          />
        </div>
      </DialogPanel>
    </div>
  </Dialog>
</template>
