import { rutValidate, rutFormat } from 'rut-helpers';

function format(dni: string) {
  return rutFormat(dni);
}

function validate(dni: string) {
  return rutValidate(dni);
}

export const clDniUtils = { format, validate };
