<script setup lang="ts">
import { useField } from 'vee-validate';
import { toRef } from 'vue';

type Props = {
  modelValue?: boolean | unknown[];
  checkedValue: unknown;
  name: string;
  disabled?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  modelValue: undefined,
  disabled: false,
});

const name = toRef(props, 'name');

const { handleChange, checked, errorMessage, meta } = useField(name, undefined, {
  type: 'checkbox',
  checkedValue: props.checkedValue,
  initialValue: props.modelValue,
});

</script>
<template>
  <div
    class="flex w-full flex-col items-start"
  >
    <div class="relative flex items-center">
      <input
        :id="name"
        :checked="checked"
        :value="checkedValue"
        type="checkbox"
        :name="name"
        :disabled="disabled"
        class="h-5 w-5 rounded"
        @change="handleChange"
      >
      <label
        :for="name"
        class="ml-2 text-sm"
      >
        <slot />
      </label>
    </div>
    <p
      v-if="errorMessage && meta.touched"
      data-testid="error"
      class="text-xs text-error-600"
    >
      {{ errorMessage }}
    </p>
  </div>
</template>
