import { computed, useAttrs } from 'vue';

export default function useAttrsWithoutClass() {
  const attrs = useAttrs();
  const filteredAttrs = computed(() => Object.fromEntries(
    Object.entries(attrs).filter(([key]) => key !== 'class'),
  ));

  return filteredAttrs;
}
