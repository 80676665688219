function format(dni: string) {
  return dni.replaceAll(/[^0-9]/g, '').replaceAll(/\B(?=(\d{3})+(?!\d))/g, '.');
}

const PERU_DNI_LENGTH = 8;
function validate(dni: string) {
  return dni.replaceAll('.', '').length === PERU_DNI_LENGTH;
}

export const peDniUtils = { format, validate };
