
<script setup lang="ts">
import { PhWarningCircle } from '@phosphor-icons/vue';
import { useField } from 'vee-validate';

import useAttrsWithoutClass from '@/composables/useAttrsWithoutClass';
import useFieldError from '@/composables/useFieldError';

export type Props = {
  name: string;
  modelValue?: string;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  prefix?: string;
  flag?: string;
};

type Emit = {
  (e: 'update:modelValue', value: string): void;
};

const props = withDefaults(
  defineProps<Props>(),
  {
    modelValue: undefined,
    label: undefined,
    icon: undefined,
    placeholder: undefined,
    disabled: false,
  },
);

const emit = defineEmits<Emit>();

defineOptions({
  inheritAttrs: false,
});

const attrsWithoutClass = useAttrsWithoutClass();

const {
  value: inputValue,
  errorMessage,
  meta,
  handleBlur,
  handleChange,
} = useField(props.name, undefined, {
  initialValue: props.modelValue,
});

function phoneFormatFunction(value: string) {
  return value.replace(/[^0-9]/g, '');
}

function handleInputChange(e: Event) {
  const input = e.target as HTMLInputElement;
  input.value = phoneFormatFunction(input.value);
  handleChange(e);
  emit('update:modelValue', input.value);
}

const { hideError, showError } = useFieldError({ errorMessage, meta });

</script>
<template>
  <div
    :class="$attrs.class"
    class="flex w-full flex-col gap-2"
  >
    <label
      v-if="label"
      data-testid="label"
      class="text-xs"
      :for="name"
      :class="{ 'text-gray-400': disabled }"
    >
      {{ label }}
    </label>
    <div class="relative w-full">
      <div
        class="flex w-full items-center gap-1 rounded-lg border px-4 py-3 text-sm leading-4 text-gray-900 caret-primary-400 placeholder:text-gray-300 hover:border-primary-500 focus:outline-primary-500 disabled:bg-gray-100 disabled:text-gray-300 disabled:hover:border-gray-300"
        :class="[{
          'border-gray-300': hideError,
          'border-error-600 bg-error-25 pr-12 hover:border-error-600 focus:outline-error-600': showError,
        }]"
      >
        <span v-if="flag">
          {{ flag }}
        </span>
        <span>
          {{ prefix }} |
        </span>
        <input
          v-bind="attrsWithoutClass"
          :id="name"
          :data-testid="`${name}-input`"
          class="grow focus:outline-none"
          :class="showError && 'bg-error-25'"
          :disabled="disabled"
          :name="name"
          :placeholder="placeholder"
          :value="inputValue"
          type="tel"
          @blur="handleBlur"
          @input="handleInputChange"
        >
      </div>
      <div
        v-if="$slots.right"
        class="absolute right-2 top-1/2 -translate-y-1/2"
      >
        <slot name="right" />
      </div>
      <ph-warning-circle
        v-if="showError && !$slots.right"
        class="absolute right-[17px] top-[13px] size-4 text-error-500"
      />
    </div>
    <div
      v-if="showError"
      data-testid="error-helper"
      class="flex gap-2 text-xs text-error-600"
    >
      <ph-warning-circle class="size-4" />
      <span>{{ errorMessage }}</span>
    </div>
  </div>
</template>
